import React from "react"
import { Link, graphql } from "gatsby"
import _ from "lodash"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Share from "../components/share"
import { Twemoji } from 'react-emoji-render'
const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="text-center pt-4 pb-2 px-4 my-4 rounded bg-gray-default">
          <div className="w-20 mx-auto">
            <Twemoji svg className="emoji-l" text={post.frontmatter.emoji} />
          </div>
          <h1 className="my-2 text-4xl md:text-3xl" itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p> 
          <div className="mt-2 mb-4 text-white">
            {post.frontmatter.tags.map(tag => (
              <Link className="tag p-1 mr-2 border bg-blue-default border-gray-default rounded hover:bg-gray-default" to={`/tags/${_.kebabCase(tag)}/`}>{tag}</Link>
            ))}
          </div>
          <Share url = { location.href } title = {post.frontmatter.title}/>
        </header>
        <section
         className="markdown-body"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
         <Share url = { location.href } title = {post.frontmatter.title}/>
        <hr className="article-end" />
      </article>
     
      <nav className="blog-post-nav my-auto py-4">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        description
        emoji
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
